import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import JoinLogo from "../images/joinOurTeamLogo.svg"
import LinkedInLogo from "../images/LDLogo.svg"
import GithubLogo from "../images/github.svg"

const IndexPage = () => (
  <Layout>
    <div className="flex justify-center items-center py-[0px] sm:py-[60px] flex-wrap h-full">
      <div className="w-full lg:w-5/12 flex justify-center">
        <StaticImage
          src="../images/ECAD.png"
          quality={95}
          width={261}
          formats={["auto", "webp", "avif"]}
          alt="ECAD Labs Logo"
          style={{ marginBottom: `1.45rem` }}
        />
      </div>

      <div className="w-full lg:w-7/12 ">
        <Seo title="Home" />
        <h1 className="max-w-[575px]">
          We build developer tools for the Tezos blockchain.
        </h1>

        <div className="flex flex-wrap">
          <div className="w-full sm:w-1/2">
            <h2>WHERE TO FIND US</h2>
            ECAD Labs Inc.
            <br />
            671-J Market Hill,
            <br />
            Vancouver, BC, V5K 4B5
            <br />
            Canada
            <br />
            <div className="text-red pt-[15px] hover:text-orange pb-6 sm:pb-0">
              <a href="mailto:info@ecadlabs.com">info@ecadlabs.com</a>
            </div>
          </div>

          <div className="w-ful sm:w-1/2 flex flex-col">
            <Link
              className="bg-orange hover:bg-red text-black py-[10px] px-[30px] py-[15px] rounded max-w-[215px] mb-[15px] ease-in-out duration-300"
              to="https://apply.workable.com/ecadlabs/"
              target="_blank"
            >
              <div className="flex gap-1 ">
                <JoinLogo className="translate-y-1" />
                <span className=" font-bold text-[20px] ">Join our team</span>
              </div>
            </Link>
            <Link
              className="bg-orange hover:bg-red text-black py-[10px] px-[30px] py-[15px] rounded max-w-[215px] mb-[15px] ease-in-out duration-300"
              to="https://www.linkedin.com/company/ecadlabs/"
              target="_blank"
            >
              <div className="flex gap-3">
                <LinkedInLogo className="translate-y-0.5" />
                <span className=" font-bold text-[20px] ">LinkedIn</span>
              </div>
            </Link>
            <Link
              className="bg-orange hover:bg-red text-black py-[10px] px-[30px] py-[15px] rounded max-w-[215px] mb-[15px] ease-in-out duration-300"
              to="https://github.com/ecadlabs/"
              target="_blank"
            >
              <div className="flex gap-3">
                <GithubLogo className="translate-y-1" />
                <span className=" font-bold text-[20px] ">Github</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
